/* You can add global styles to this file, and also import other style files */
html, body {
    width: 100%;
    height: 100%;
}

.app-container {
    width: 100%;
    height: 100%;
    /*background-image: url('src/assets/background.jpg');*/
    /*background-image: url('src/assets/light-wood.jpg');*/
    /* background-image: url('src/assets/light-wood.jpg'); */
    /*background-color: rgb(150, 149, 150);*/
}
